<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Kantor Cabang</h5>
            <p class="mb-0 text-sm">
              Daftar kantor cabang pada struktur organisasi Anda
            </p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="showAddForm"
                  >
                    Tambah Data
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      style="border-radius: 0px 5px 5px 0px"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="
                  col-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Actions"
                  >
                    <template #default="props">
                      <a v-show="false">
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Edit data"
                        placement="top-start"
                      >
                        <a
                          class="mx-3"
                          href="#"
                          @click.prevent="showEditForm(props.$index, props.row)"
                        >
                          <i class="fas fa-user-edit text-primary"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Hapus data"
                        placement="top-start"
                      >
                        <a
                          href="#"
                          @click.prevent="handleDelete(props.$index, props.row)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <br />
              <div
                class="
                  col-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <h5>Data Form Kantor Cabang</h5>
      <form @submit.prevent="onSubmit">
        <input v-model="form.id" type="hidden" />
        <div class="row">
          <div class="col-md-2">
            <label class="form-label">Kode</label>
            <input
              v-model="form.code"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-5">
            <label class="form-label">Nama</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-5">
            <label class="form-label">Alamat</label>
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="form-label">Propinsi</label>
            <select
              v-model="form.province_id"
              class="form-select"
              required
              @change="handleSelectCity"
            >
              <option
                v-for="select in selects.provinces"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-label">Kota/Kabupaten</label>
            <select
              v-model="form.city_id"
              class="form-select"
              required
              @change="handleSelectDistrict"
            >
              <option
                v-for="select in selects.cities"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="form-label">Kecamatan</label>
            <select
              v-model="form.district_id"
              class="form-select"
              required
              @change="handleSelectSubDistrict"
            >
              <option
                v-for="select in selects.districts"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-label">Kelurahan</label>
            <select v-model="form.subdistrict_id" class="form-select" required>
              <option
                v-for="select in selects.subdistricts"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <label class="form-label">Kodepos</label>
            <input
              v-model="form.postalcode"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label">Telepon</label>
            <input
              v-model="form.phone"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-4">
            <label class="form-label">Fax</label>
            <input
              v-model="form.fax"
              type="text"
              class="form-control"
            />
            <div class="form-text"><i>kolom ini boleh tidak diisi.</i></div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <label class="form-label">Catatan</label>
            <textarea v-model="form.note" rows="5" class="form-control" />
            <div class="form-text"><i>kolom ini boleh tidak diisi.</i></div>
          </div>
          <div class="col-md-2"></div>
        </div>

        <hr style="margin-bottom: -20px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 35px"
          @click="modals.classic = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import ArgonButton from "@/components/ArgonButton.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElTooltip,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "BranchOffices",
  components: {
    BasePagination,
    Auth,
    ArgonButton,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Modal,
  },
  data() {
    return {
      modals: {
        classic: false,
      },
      urlread: "/api/v1/branchoffice/read",
      urlstore: "/api/v1/branchoffice/store",
      urlupdate: "/api/v1/branchoffice/update",
      urldelete: "/api/v1/branchoffice/delete",
      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["code", "name", "address"],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "code",
          label: "Kode",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "name",
          label: "Nama",
          minWidth: 100,
          sortable: true,
        },
        {
          prop: "address",
          label: "Alamat",
          minWidth: 100,
          sortable: true,
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

      selects: {
        provinces: [],
        cities: [],
        districts: [],
        subdistricts: [],
      },

      form: {
        id: "",
        code: "",
        name: "",
        address: "",
        province_id: "",
        city_id: "",
        district_id: "",
        subdistrict_id: "",
        postalcode: "",
        phone: "",
        fax: "",
        note: "",
      },
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
    this.getProvinceSelect();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getProvinceSelect() {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.provinces = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getCitySelect(province_id) {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/city/select/" + province_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.cities = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getDistrictSelect(city_id) {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/district/select/" + city_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.districts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getSubDistrictSelect(district_id) {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/subdistrict/select/" + district_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.subdistricts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getTables() {
      try {
        this.loadingService();
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data.datatables;
        this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    showAddForm() {
      this.modals.classic = true;
      this.clearForm();
    },
    async showEditForm(index, row) {
      this.modals.classic = true;
      this.clearForm();
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }
        this.getCitySelect(this.form.province_id);
        this.getDistrictSelect(this.form.city_id);
        this.getSubDistrictSelect(this.form.district_id);

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    refreshTable() {
      this.getTables();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        let sendurl = this.urlstore;
        if (this.form.id != "") {
          // update
          sendmethod = "put";
          sendurl = this.urlupdate;
        }
        const response = await axios({
          method: sendmethod,
          url: sendurl,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Data berhasil diupdate!", "success");
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Konfirmasi!",
        html: `Yakin ingin menghapus data ini!<br /><b>` + row.name + `</b>`,
        showCancelButton: true,
        confirmButtonText: "Yes, hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteRow(row);
        }
      });
    },
    async deleteRow(row) {
      this.loadingService();
      try {
        const response = await axios({
          method: "delete",
          url: this.urldelete + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data);
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        }else{
          let indexToDelete = this.tableData.findIndex(
            (tableRow) => tableRow.id === row.id
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          Swal.fire("Good job!", "Data berhasil dihapus!", "success");
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
    handleSelectCity() {
      console.log("handle select city");
      this.getCitySelect(this.form.province_id);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.city_id);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.district_id);
    },
  },
};
</script>
